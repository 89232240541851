import React from 'react';

import './LoadingView.css';

const LoadingView = () =>
{
	return (
		<div className='LoadingView' />
	);
};

export default LoadingView;
